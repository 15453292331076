import axios, { InternalAxiosRequestConfig } from 'axios';

const idpUrl = 'https://idp.empyro.duckdns.org';

axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    request.withCredentials = true;

    const token = sessionStorage.getItem('token');

    request.headers['Authorization'] = `Bearer ${token}`;

    return request;
});

axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    if (401 === error.response.status) {
        const res = await axios.get(`${idpUrl}/api/user/session`, {
            withCredentials: true
        });
        const userInfo = res.data;
        const token = userInfo?.token;

        if (token) {
            sessionStorage.setItem('token', token);
            return axios.request(error.config);
        } else {
            window.location.href = `${idpUrl}?returnUrl=${encodeURIComponent(window.location.href)}`;
        }
    } else {
        return Promise.reject(error);
    }
});
